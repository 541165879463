:root {
  --font-body: Arial, sans-serif;
}

:root {
  --white: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  max-width: rem(425);
}

h1,
h2,
h3 {
  margin-top: 0;
  line-height: 1.1;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.container {
  padding: 0 1.5rem;
  max-width: 69.375rem;
  margin: 0 auto;
}
@media (min-width: 71.875em) {
  .container {
    padding: 0;
    transform: translate(0, 0);
  }
}

body {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", Arial, sans-serif;
  background-image: url("/images/table.png");
  background-size: cover;
  background-repeat: no-repeat;
  font-weight: bold;
  color: white;
  text-align: center;
}

h1 {
  color: goldenrod;
}

#message-el {
  font-style: italic;
}

button {
  color: #016f32;
  width: 150px;
  background: goldenrod;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: bold;
  border: none;
  border-radius: 2px;
  margin-bottom: 2px;
  margin-top: 2px;
}