@use '../util' as *;
body {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', Arial, sans-serif;
    background-image: url("/images/table.png");
    background-size: cover;
    background-repeat: no-repeat;
    font-weight: bold;
    color: white;
    text-align: center;
}

h1 {
    color: goldenrod;
}

#message-el {
    font-style: italic;
}

button {
    color: #016f32;
    width: 150px;
    background: goldenrod;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
    border: none;
    border-radius: 2px;
    margin-bottom: 2px;
    margin-top: 2px;
}